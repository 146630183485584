<template>
  <div>
    <SystemsTable
      :items="systems"
      :total-size="totalSize"
      :page-size="pageSize"
      :page-number="pageNumber"
      :loading="isLoading"
      :title="$t('systems.table.title')"
      :features="features"
      @pageChanged="pageChanged"
      @itemCreated="onItemCreated"
      @itemUpdated="onItemUpdated"
      @itemDeleted="onItemDeleted"
      @sortingChanged="onSortingChanged"
      @refreshRequested="onRefreshRequested"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import SystemsTable from '@/components/tables/SystemsTable'

export default {
  components: { SystemsTable },
  data () {
    return {
      systems: [],
      isLoading: true,
      totalSize: 0,
      pageSize: 25,
      pageNumber: 1,
      sorting: undefined
    }
  },
  computed: {
    ...mapGetters('account', ['isOverlord']),
    features () {
      return this.isOverlord ? ['create', 'update', 'delete'] : ['create', 'update']
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('userGroups', ['fetchAllUserGroups']),
    ...mapActions('projects', ['fetchAllProjects']),
    ...mapActions('systems', ['fetchSystems']),
    onItemUpdated (updatedSystem) {
      // Try to find the updated system among the currently shown systems.
      const index = this.systems.findIndex((system) => system.id === updatedSystem.id)
      if (index !== -1) {
        // Update the system to the new values.
        // $set is a special method to allow vue to react to array changes.
        // See https://vuejs.org/v2/guide/reactivity.html for more info.
        this.$set(this.systems, index, updatedSystem)
      }
    },
    async onItemCreated (newSystem) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.sorting)
    },
    async onItemDeleted (deletedSystem) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.sorting)
    },
    async onRefreshRequested () {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onSortingChanged (newSorting) {
      this.sorting = newSorting
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.sorting)
    },
    fetchPageDebounced: debounce(async function (pageNumber, pageSize, sorting) {
      await this.fetchPage(pageNumber, pageSize, sorting)
    }, 250),
    async pageChanged (newPageNumber) {
      // Update the current page number.
      this.pageNumber = newPageNumber
      // Fetch data with the new page number.
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.sorting)
    },
    async fetchPage (pageNumber, pageSize, sorting) {
      this.isLoading = true
      try {
        // Fetch all user groups and projects.
        await Promise.all([
          this.fetchAllUserGroups(),
          this.fetchAllProjects()
        ])
        // Fetch all systems.
        const pagedResponse = await this.fetchSystems({ pageNumber, pageSize, sorting })
        this.systems = pagedResponse.items
        this.totalSize = pagedResponse.totalSize
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  created () {
    this.pageNumber = parseInt(this.$route.query.page || this.pageNumber)
  },
  async mounted () {
    await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.sorting)
  },
  watch: {
    pageNumber (newVal, oldVal) {
      // Do nothing if the route is already correct.
      if (this.$route.query.page && parseInt(this.$route.query.page) === newVal) return
      // Replace the page number in the route/url to be the new page number.
      const query = { ...this.$route.query, page: newVal }
      this.$router.replace({ query })
    }
  }
}
</script>
