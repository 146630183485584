<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card :loading="isLoading">
      <v-form>
        <!-- Title -->
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <!-- Content -->
        <v-card-text>
          <v-container>
            <v-row>
              <!-- Name -->
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  :label="$t('systems.dialog.name-label')"
                  :error-messages="validationErrors['name']"
                  :error-count="5"
                />
              </v-col>

              <!-- Project -->
              <v-col cols="12">
                <v-select
                  :items="projects"
                  v-model="selectedProject"
                  item-text="name"
                  :label="$t('systems.dialog.project-label')"
                  :error-messages="validationErrors['projectId']"
                  :error-count="5"
                  clearable
                  return-object
                />
              </v-col>

              <!-- Fuel type -->
              <v-col cols="12">
                <v-select
                  :items="fuelTypes"
                  v-model="selectedFuelType"
                  item-value="value"
                  :item-text="fuelType => $t(fuelType.translationKey)"
                  :label="$t('systems.dialog.fuel-type-label')"
                  :error-messages="validationErrors['fuelType']"
                  :error-count="5"
                />
              </v-col>

              <!-- Usergroups -->
              <v-col cols="12">
                <v-autocomplete
                  :label="$t('systems.dialog.usergroups-label')"
                  :search-input.sync="userGroupSearchString"
                  @change="userGroupSearchString = ''"
                  v-model="selectedUserGroupIds"
                  :items="userGroups"
                  item-text="name"
                  item-value="id"
                  chips
                  deletable-chips
                  clearable
                  multiple
                  class="pa-0 ma-0"
                  :hint="$t('systems.dialog.usergroups-hint')"
                  :error-messages="validationErrors['userGroupIds']"
                  :error-count="3"
                  persistent-hint
                />
              </v-col>

              <!-- User -->
              <v-col cols="12">
                <v-combobox
                  :items="systemUsers"
                  v-model="selectedUser"
                  item-text="emailAddress"
                  return-object
                  clearable
                  :label="$t('systems.dialog.user-label')"
                  :error-messages="validationErrors['userId']"
                  :error-count="5"
                  :hint="$t('systems.dialog.user-hint')"
                  persistent-hint
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Buttons -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="close"
          >
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="save"
            :loading="isSaving"
            :disabled="isLoading"
          >
            {{ $t('common.buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  props: {
    /**
     * Indicates whether this dialog should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true },
    system: { type: Object, default: null }
  },
  emits: ['itemCreated', 'itemUpdated'],
  data () {
    return {
      name: '',
      isSaving: false,
      selectedFuelType: 0,
      selectedProject: null,
      selectedUserGroupIds: [],
      selectedUser: null,
      userGroupSearchString: '',
      validationErrors: {},
      lastSuccessfulLoad: null,
      isLoading: false
    }
  },
  computed: {
    ...mapState('projects', ['projects']),
    ...mapState('fuelTypes', ['fuelTypes']),
    ...mapState('userGroups', ['userGroups']),
    ...mapState('users', ['systemUsers']),
    ...mapGetters('projects', ['getProjectById']),
    isNew () {
      return this.system == null
    },
    refetchImminent () {
      return !this.lastSuccessfulLoad || Date.now() - this.lastSuccessfulLoad > 60 * 1000
    },
    formTitle () {
      return this.$t(this.isNew ? 'systems.dialog.create-title' : 'systems.dialog.edit-title')
    },
    showDialog: {
      get: function () { return this.value },
      set: function (newValue) { this.$emit('input', newValue) }
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('userGroups', ['fetchAllUserGroups']),
    ...mapActions('projects', ['fetchAllProjects']),
    ...mapActions('users', ['fetchSystemUsers']),
    close () {
      this.showDialog = false
    },
    async save () {
      this.isSaving = true
      this.validationErrors = {}

      try {
        // Send a request to create/update the item.
        const response = this.isNew ? await this.createNew() : await this.updateExisiting()
        // Notify the dialog has saved the item.
        const event = this.isNew ? 'itemCreated' : 'itemUpdated'
        this.$emit(event, response.data)
        // Show a message indicating the save succeeded.
        this.showSnackbar({
          role: 'success',
          messages: [this.$t('common.save-success-message', { entityName: this.$t('common.system') })],
          duration: 5000
        })
        // Close the dialog.
        this.close()
      } catch (error) {
        console.error(error)
        if (error.response.status === 400 && error.response && error.response.data && error.response.data.errors) {
          this.validationErrors = error.response.data.errors
        } else {
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('common.save-failed-message', { entityName: this.$t('common.system') })],
            duration: 5000
          })
        }
      } finally {
        this.isSaving = false
      }
    },
    async updateExisiting () {
      const payload = {
        id: this.system.id,
        name: this.name,
        fuelType: this.selectedFuelType,
        projectId: this.selectedProject?.id,
        userId: this.selectedUser?.id,
        userGroupIds: this.selectedUserGroupIds
      }
      return await axios.put('systems', payload)
    },
    async createNew () {
      const payload = {
        name: this.name,
        fuelType: this.selectedFuelType,
        projectId: this.selectedProject?.id,
        userId: this.selectedUser?.id,
        userGroupIds: this.selectedUserGroupIds
      }
      return await axios.post('systems', payload)
    },
    async fetchData () {
      try {
        this.isLoading = true
        await Promise.all([
          this.fetchAllProjects(),
          this.fetchAllUserGroups(),
          this.fetchSystemUsers()
        ])

        // We need to update the selected user, because the users might not've been fetched yet before this method.
        this.selectedUser = this.system ? this.getUserById(this.system.userId) : null
        // We need to update the selected project, because the projects might not've been fetched yet before this method.
        this.selectedProject = this.system ? this.getProjectById(this.system.projectId) : null

        this.lastSuccessfulLoad = Date.now()
      } catch (error) {
        // Show message that loading data failed.
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
        // Close the dialog.
        this.close()
      } finally {
        this.isLoading = false
      }
    },
    getUserById () {
      return this.systemUsers.find(user => user.id === this.system.userId)
    }
  },
  watch: {
    // Watch for value (v-model) changes. Occurs when the dialog is opened/closed.
    value: {
      immediate: true,
      async handler (newVal, oldVal) {
        // Only update the values if the dialog is being opened.
        if (newVal) {
          this.name = this.system?.name ? this.system.name : ''
          this.selectedFuelType = this.system?.fuelType ? this.system.fuelType : 0
          this.selectedProject = this.system ? this.getProjectById(this.system.projectId) : null
          this.selectedUser = this.system ? this.getUserById(this.system.userId) : null
          this.selectedUserGroupIds = this.system ? this.system.userGroupIds : []
          this.validationErrors = {}

          if (this.refetchImminent) await this.fetchData()
        }
      }
    }
  }
}
</script>
