<template>
  <span>
    {{ project == null ? '-' : project.name }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    projectId: { type: Number, required: true }
  },
  computed: {
    ...mapGetters('projects', ['getProjectById']),
    project () {
      return this.getProjectById(this.projectId)
    }
  }
}
</script>
