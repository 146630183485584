<template>
  <span>
    {{ displayText }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    ids: { type: Array, required: true }
  },
  computed: {
    ...mapGetters('userGroups', ['getUserGroupsByIds']),
    userGroups () {
      return this.getUserGroupsByIds(this.ids)
    },
    userGroupNames () {
      return this.userGroups.map(r => r.name)
    },
    displayText () {
      return this.userGroupNames.length > 0 ? this.userGroupNames.join(', ') : '-'
    }
  }
}
</script>
